var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(_vm.filteredCompanies!=null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.filteredCompanies,"headers":this.companiesHeader,"search":_vm.companiesSearch,"footer-props":{
        'items-per-page-options':[10,20,50,100]
    },"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.companies.indexOf(item)+1)+" ")]}},{key:"item.company_name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'PageCompaniesDetail',params:{id:item.company_id}}}},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3"},[_c('v-row',{staticClass:"mb-5 ml-1"},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pr-0"},[_c('span',{staticClass:"mt-5 grey--text text-h7 ml-2 mb-2"},[_vm._v(" Subscribed Date : ")]),_c('v-row',[_c('v-col',{staticClass:"pr-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"Start Date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                                        _vm.isStartDate = true;
                                                    },"click":function (){
                                                        _vm.isStartDate = true;
                                                    }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isStartDate),callback:function ($$v) {_vm.isStartDate=$$v},expression:"isStartDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" Start date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('div',{staticClass:"footer",attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isStartDate = false;
                                                                    _vm.startDate = null;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isStartDate = false;
                                                                }}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)]),_c('v-col',{staticClass:"pl-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"End Date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                                    _vm.isEndDate = true;
                                                },"click":function (){
                                                    _vm.isEndDate = true;
                                                }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isEndDate),callback:function ($$v) {_vm.isEndDate=$$v},expression:"isEndDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" End Date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('div',{staticClass:"footer",attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isEndDate = false;
                                                                    _vm.endDate = null;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isEndDate = false;
                                                                }}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)])],1)],1),_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"mt-5 grey--text text-h7 ml-2 mb-2 "},[_vm._v(" Expired Date : ")]),_c('v-row',[_c('v-col',{staticClass:"pr-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"Start date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                                    _vm.isStartDateExpired = true;
                                                },"click":function (){
                                                    _vm.isStartDateExpired = true;
                                                }},model:{value:(_vm.startDateExpired),callback:function ($$v) {_vm.startDateExpired=$$v},expression:"startDateExpired"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isStartDateExpired),callback:function ($$v) {_vm.isStartDateExpired=$$v},expression:"isStartDateExpired"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" Start Date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.startDateExpired),callback:function ($$v) {_vm.startDateExpired=$$v},expression:"startDateExpired"}}),_vm._t("footer",function(){return [_c('div',{staticClass:"d-flex justify-end white"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isStartDateExpired=false;
                                                                    _vm.startDateExpired = null;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isStartDateExpired = false;
                                                                }}},[_vm._v(" Confirm ")])],1)]})],2)],1)],1)]),_c('v-col',{staticClass:"pl-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px 4px 4px 0px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"End Date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                                    _vm.isEndDateExpired = true;
                                                },"click":function (){
                                                    _vm.isEndDateExpired = true;
                                                }},model:{value:(_vm.endDateExpired),callback:function ($$v) {_vm.endDateExpired=$$v},expression:"endDateExpired"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isEndDateExpired),callback:function ($$v) {_vm.isEndDateExpired=$$v},expression:"isEndDateExpired"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" End Date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.endDateExpired),callback:function ($$v) {_vm.endDateExpired=$$v},expression:"endDateExpired"}}),_c('div',{staticClass:"footer",attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isEndDateExpired = false;
                                                                    _vm.endDateExpired = false;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isEndDateExpired = false;
                                                                }}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)])],1)],1)],1)],1),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('JsonCSV',{attrs:{"data":_vm.filteredCompanies}},[_c('v-btn',{attrs:{"color":"primary","text":""}},[_vm._v(" Download ")])],1)],1),_c('div',[_c('div',{staticClass:"px-2"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary"},on:{"click":_vm.filterCompaniesSubscriptionDate}},[_vm._v(" Filter ")])],1)])])])],1),_c('v-row',{staticClass:"mt-7 pt-4 "},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.status=='Active')?_c('v-select',{attrs:{"dense":"","outlined":"","label":"Filter subscription...","item-text":"text","item-value":"value","return-object":"","items":_vm.subscriptionOptions},on:{"change":_vm.filterCompaniesSubscription},model:{value:(_vm.subscriptionFilter),callback:function ($$v) {_vm.subscriptionFilter=$$v},expression:"subscriptionFilter"}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search..."},model:{value:(_vm.companiesSearch),callback:function ($$v) {_vm.companiesSearch=$$v},expression:"companiesSearch"}})],1)],1),_c('v-row',{staticClass:"mx-4 my-4"},[(_vm.totalCompanies!=null)?_c('div',[_c('span',{staticClass:"text-h7 grey--text"},[_vm._v(" Total "+_vm._s(_vm.status)+" Companies : "+_vm._s(_vm.totalCompanies)+" ")])]):_vm._e()])],1)],1)],1)]},proxy:true},{key:"item.period_start_date",fn:function(ref){
                                                                var item = ref.item;
return [(item.period_start_date!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.period_start_date))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.period_end_date",fn:function(ref){
                                                                var item = ref.item;
return [(item.period_end_date!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.period_end_date))+" ")]):_c('span')]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }