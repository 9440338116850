<template>
    <v-container>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-data-table :search="companySearch" v-if="companies!=null && !api.isLoading" :items="companies" :headers="this.companiesHeader" loading-text="Data loading...">
            <template v-slot:top>
                <v-toolbar flat height="auto">
                    <v-row no-gutters>
                        <v-col>
                            <!-- <v-row class="mb-4">
                                <v-toolbar-title class="text-h4 font-weight-bold">
                                    Company
                                </v-toolbar-title>
                            </v-row> -->
                            <v-row class="mt-5">
                                <v-text-field v-model="companySearch" label="Search company's name" clearable filled outlined dense>
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.index="{item}">
                {{ companies.indexOf(item)+1 }}
            </template>
            <template v-slot:item.name="{item}">
                <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.name }}
                </router-link>
            </template>
            <!-- <template v-slot:item.verification="{item}">
                <div class="mt-3">
                    <v-select @change="updateCompanyVerification(item)" dense outlined :items="verificationStatusOption" v-model="item.verification">
    
                    </v-select>

                </div>
            </template> -->
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    'verification'
    ],
    data: () => ({
    companySearch:null,
    verificationStatusOption:['valid','invalid','internal','unknown'],
    companies:null,
    companiesHeader:[
        {
            text:'#',
            value:'index',
            align:'start',
        },
        {
            text:'Company Name',
            value:'name',
        },
        {
            text:'Join Date',
            value:'created_at',
        },

    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=='getCompaniesByVerificationStatus'){
                this.companies = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesVerificationApi = this.fetchCompaniesVerification();
            this.$api.fetch(fetchCompaniesVerificationApi);
        },    
        fetchCompaniesVerification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url=process.env.VUE_APP_SERVER_API+"/company/verification/"+this.verification;
            return tempApi;
        },
        updateCompanyVerification(company){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/verification/"+company.verification;
            tempApi.params={
                'id':company.company_id,
            };
            this.$axios.all([
                this.$api.fetch(tempApi),
                location.reload(),
                
            ])

        },
    }
}
</script>