<template>
   <v-container>
      <AError :api="this.api" />

      <v-container class="" v-if="this.allowedRoles.some(role => this.$store.getters.getRole.includes(role))">
         <v-toolbar class="pa-4" flat>

            <v-tabs v-model="companyTab">
               <v-btn color="primary" class="mx-2" outlined to="#valid">
                  <v-tab to="#valid">
                     Valid
                  </v-tab>
               </v-btn>
               <v-btn color="primary" class="mr-2" outlined to="#invalid">
                  <v-tab to="#invalid">
                     Invalid
                  </v-tab>
               </v-btn>
               <v-btn color="primary" class="mr-2" outlined to="#unknown">
                  <v-tab to="#unknown">
                     Unknown
                  </v-tab>

               </v-btn>
               <v-btn color="primary" class="mr-2" outlined to="#internal">
                  <v-tab to="#internal">
                     Internal
                  </v-tab>
               </v-btn>
               <v-spacer>
               </v-spacer>
               <v-btn color="primary" :to="{ name: 'PageCompaniesCreate' }">
                  New
               </v-btn>
            </v-tabs>
         </v-toolbar>
         <v-tabs-items v-model="companyTab">
            <v-tab-item id="valid" class="pa-5" flat>
               <div>
                  <v-tabs v-model="subscriptionTab" class="d-flex justify-center">
                     <v-tab>
                        Active
                     </v-tab>
                     <v-tab>
                        Expired
                     </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="subscriptionTab">

                     <v-tab-item>
                        <PageCompaniesListSubscription status="Active" />
                     </v-tab-item>

                     <v-tab-item>
                        <PageCompaniesListSubscription status="Expired" />
                        <!-- <v-skeleton-loader
                                    v-if="this.firstLoading.isLoading"
                                    ref="skeleton"
                                    class="ma-auto"
                                    type="table">
                        
                                    </v-skeleton-loader>
                                    <v-data-table v-if="!this.firstLoading.isLoading"
                                    :items="this.data"
                                    :headers="this.headers"
                                    :loading = "loadingData"
                                    :items-per-page="20"
                                    loading-text-="Data loading..."
                                    class="elevation-1"
                                    hide-default-footer
                                    dense
                                    >
                                        <template v-slot:top>
                                            <v-toolbar
                                            flat
                                            class=""
                                            height="auto" >

                                                <v-row
                                                no-gutters
                                                color="primary"
                                                class="mt-5">
                                                <v-col
                                                class="mt-3 mx-3">
                                                    <v-row align="center" justify="center">
                                                        <div
                                                        class="pb-2 d-flex justify-center">
                                                            <v-pagination
                                                                color="primary"
                                                                v-model="pageNumber"
                                                                @input='fetchCompanies'
                                                                :length="totalPage"
                                                                
                                                                :total-visible="7">
                                                            </v-pagination>
                                                        </div>
                                                    </v-row>
                                                    <v-row
                                                    class="pt-4">
                                                        <v-select
                                                        label="Subscription Status"
                                                        v-model="statusSearch"
                                                        :items="statusCompanies"
                                                        @change="fetchCompanies(pageNumber)"
                                                        dense
                                                        outlined>
                                                        </v-select>
                                                        <v-spacer>
                                                        </v-spacer>
                                                        <v-form @submit.prevent="fetchCompanies(1)">
                                                            <v-text-field
                                                                class=" mr-4"
                                                                v-model="companyName"
                                                                elevation="4"
                                                                outlined
                                                                prepend-icon="mdi-magnify"
                                                                label="Search Company's name"
                                                                clearable
                                                                filled
                                                                @click:prepend="fetchCompanies(1)"
                                                                @click:append-outer="fetchCompanies(1)"
                                                                @click:clear="clearSearch()">
                                                            </v-text-field>
                    
                                                        </v-form>
                                                    </v-row>
                                                    <v-row
                                                    class="mb-2"
                                                    align-self="end">
                                                        <div class="grey--text"> 
                                                            Total companies found : {{ totalCompanies }}
                                                        </div>
                                                    </v-row>
                                                </v-col>
                                                </v-row>
                                            </v-toolbar>
                                            <v-divider></v-divider>
                                        </template>
                        
                                        <template
                                        v-slot:header.name="{item}">
                                            Company Name
                                            {{ item }}
                                            <v-icon
                                            color="primary"
                                            id = "sortAscc.name"
                                            @click="sortFetchCompanies('sortAsc','c.name',$event)">
                                                mdi-arrow-down
                                            </v-icon>
                                            <v-icon
                                            color="primary"
                                            id=sortDescc.name
                                            @click="sortFetchCompanies('sortDesc','c.name',$event)">
                                                mdi-arrow-up
                                            </v-icon>
                                        </template> 
                                        <template v-slot:item.name="{item}">
                                            <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}" class="text-decoration-none">
                                                {{ item.name }}
                                            </router-link>
                                        </template>
                    
                                        <template
                                        v-slot:item.index="{item}">
                                        {{ (data.indexOf(item)+1)+(20*parseInt(nextPage))-20 }}
                                    </template>
                    
                                    <template v-slot:item.created_at="{item}">
                                        <span v-if="item.created_at!=null">
                                            {{ convertTimeZone(item.created_at) }}
                                            {{ item.created_at.split("T")[0].split(" ")[0] }}
                                            {{ convertTimeZone(item.created_at).split(" ")[3] }} 
                                            {{ convertTimeZone(item.created_at).split(" ")[4] }} 

                                        </span>
                                        <span v-else >
                                            -
                                        </span>
                                    </template>
                                        <template
                                        v-slot:header.created_at>
                                            Subscribe date
                                            <v-icon
                                            color="primary"
                                            id = "sortAsciv.created_at"
                                            @click="sortFetchCompanies('sortAsc','iv.created_at',$event)">
                                                mdi-arrow-down
                                            </v-icon>
                                            <v-icon
                                            color="primary"
                                            id = "sortDesciv.created_at"
                                            @click="sortFetchCompanies('sortDesc','iv.created_at',$event)">
                                                mdi-arrow-up
                                            </v-icon>
                                        </template>
                                    </v-data-table> -->
                     </v-tab-item>


                  </v-tabs-items>


               </div>
            </v-tab-item>
            <v-tab-item id="invalid" class="pa-5">
               <PageCompaniesListVerification verification="invalid" />
            </v-tab-item>
            <v-tab-item id="unknown" class="pa-5">
               <PageCompaniesListVerification verification="unknown" />
            </v-tab-item>
            <v-tab-item id="internal" class="pa-5">
               <PageCompaniesListVerification verification="internal" />
            </v-tab-item>
         </v-tabs-items>
      </v-container>
      <v-container v-else>
         <div>
            <v-toolbar flat class="">

               <v-tabs v-model="subscriptionTab" class="d-flex justify-center">
                  <v-tab>
                     Active
                  </v-tab>
                  <v-tab>
                     Expired
                  </v-tab>
               </v-tabs>
            </v-toolbar>
            <v-tabs-items v-model="subscriptionTab">

               <v-tab-item>
                  <PageCompaniesListSubscription status="Active" />
               </v-tab-item>

               <v-tab-item>
                  <PageCompaniesListSubscription status="Expired" />
                  <!-- <v-skeleton-loader
                        v-if="this.firstLoading.isLoading"
                        ref="skeleton"
                        class="ma-auto"
                        type="table">
            
                        </v-skeleton-loader>
                        <v-data-table v-if="!this.firstLoading.isLoading"
                        :items="this.data"
                        :headers="this.headers"
                        :loading = "loadingData"
                        :items-per-page="20"
                        loading-text-="Data loading..."
                        class="elevation-1"
                        hide-default-footer
                        dense
                        >
                            <template v-slot:top>
                                <v-toolbar
                                flat
                                class=""
                                height="auto" >

                                    <v-row
                                    no-gutters
                                    color="primary"
                                    class="mt-5">
                                    <v-col
                                    class="mt-3 mx-3">
                                        <v-row align="center" justify="center">
                                            <div
                                            class="pb-2 d-flex justify-center">
                                                <v-pagination
                                                    color="primary"
                                                    v-model="pageNumber"
                                                    @input='fetchCompanies'
                                                    :length="totalPage"
                                                    
                                                    :total-visible="7">
                                                </v-pagination>
                                            </div>
                                        </v-row>
                                        <v-row
                                        class="pt-4">
                                            <v-select
                                            label="Subscription Status"
                                            v-model="statusSearch"
                                            :items="statusCompanies"
                                            @change="fetchCompanies(pageNumber)"
                                            dense
                                            outlined>
                                            </v-select>
                                            <v-spacer>
                                            </v-spacer>
                                            <v-form @submit.prevent="fetchCompanies(1)">
                                                <v-text-field
                                                    class=" mr-4"
                                                    v-model="companyName"
                                                    elevation="4"
                                                    outlined
                                                    prepend-icon="mdi-magnify"
                                                    label="Search Company's name"
                                                    clearable
                                                    filled
                                                    @click:prepend="fetchCompanies(1)"
                                                    @click:append-outer="fetchCompanies(1)"
                                                    @click:clear="clearSearch()">
                                                </v-text-field>
        
                                            </v-form>
                                        </v-row>
                                        <v-row
                                        class="mb-2"
                                        align-self="end">
                                            <div class="grey--text"> 
                                                Total companies found : {{ totalCompanies }}
                                            </div>
                                        </v-row>
                                    </v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-divider></v-divider>
                            </template>
            
                            <template
                            v-slot:header.name="{item}">
                                Company Name
                                {{ item }}
                                <v-icon
                                color="primary"
                                id = "sortAscc.name"
                                @click="sortFetchCompanies('sortAsc','c.name',$event)">
                                    mdi-arrow-down
                                </v-icon>
                                <v-icon
                                color="primary"
                                id=sortDescc.name
                                @click="sortFetchCompanies('sortDesc','c.name',$event)">
                                    mdi-arrow-up
                                </v-icon>
                            </template> 
                            <template v-slot:item.name="{item}">
                                <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}" class="text-decoration-none">
                                    {{ item.name }}
                                </router-link>
                            </template>
        
                            <template
                            v-slot:item.index="{item}">
                            {{ (data.indexOf(item)+1)+(20*parseInt(nextPage))-20 }}
                        </template>
        
                        <template v-slot:item.created_at="{item}">
                            <span v-if="item.created_at!=null">
                                {{ convertTimeZone(item.created_at) }}
                                {{ item.created_at.split("T")[0].split(" ")[0] }}
                                {{ convertTimeZone(item.created_at).split(" ")[3] }} 
                                {{ convertTimeZone(item.created_at).split(" ")[4] }} 

                            </span>
                            <span v-else >
                                -
                            </span>
                        </template>
                            <template
                            v-slot:header.created_at>
                                Subscribe date
                                <v-icon
                                color="primary"
                                id = "sortAsciv.created_at"
                                @click="sortFetchCompanies('sortAsc','iv.created_at',$event)">
                                    mdi-arrow-down
                                </v-icon>
                                <v-icon
                                color="primary"
                                id = "sortDesciv.created_at"
                                @click="sortFetchCompanies('sortDesc','iv.created_at',$event)">
                                    mdi-arrow-up
                                </v-icon>
                            </template>
                        </v-data-table> -->
               </v-tab-item>


            </v-tabs-items>


         </div>
      </v-container>
   </v-container>
</template>
<script>
import PageCompaniesListSubscription from './PageCompaniesListSubscription.vue';
import PageCompaniesListVerification from './PageCompaniesListVerification.vue';


export default {
   components: {
      PageCompaniesListVerification,
      PageCompaniesListSubscription
   },
   computed: {

   },

   data: () => ({
      subscriptionTab: null,
      companyTab: null,
      userRole: null,
      allowedRoles: ['developer', 'vision_admin', 'operation', 'customer_service', 'sales_manager'],
      toggleNone: null,
      isSorted: {
         name: {
            asc: false,
            desc: false,
         },
         salesperson: {
            asc: false,
            desc: false,
         },
         created: {
            asc: false,
            desc: false,
         },
      },
      firstLoading: {
         isLoading: true,
         isSuccesful: false,
      },
      sort: {
         order: 'sortDesc',
         column: 'iv.created_at',
      },
      headers: [
         {
            text: '#',
            value: 'index',
            align: 'start',
            sortable: false,
         },
         {
            text: 'Name',
            value: 'name',
            sortable: false,
         },
         {
            text: 'Subscription',
            value: 'plan_name',
         },
         {
            text: 'Subscription Status',
            value: 'status',
            sortable: false,
         },
         {
            text: 'Subscribe Date',
            value: 'created_at',
            sortable: false,
         },


      ],
      datepicker: false,
      companyName: null,
      salespersonName: null,
      data: [null],
      plan_available: [null],
      salesPerson: [null],
      dialog: false,
      dialogNote: false,
      editedCompanies: {
         id: null,
         created_at: null,
         name: null,
         plan: {
            id: null,
            plan_name: null,
         },
         renew_at: null,
         // followup_at:null,
         status: null,
         address: "",
         companySize: 0,
         industry: null,
         pic: {
            role_id: null,
            mobile: null,
            names: null,
            user_email: null,
         }
      },
      statusSearch: 'All',
      statusCompanies: ['All', 'Active', 'Expired', 'Expires in 30 Days', 'Expires in 90 Days'],
      pageNumber: 1,
      nextPage: 0,
      totalPage: 0,
      totalCompanies: 0,
      api: {
         isLoading: false,
         isError: false,
         error: null,
         url: null,
      },
      promisesFetchCompanies: null,
      promisesFetchPlan: null,
      loadingData: true,
   }),
   created() {
      this.api.method = "get",
         this.api.url = process.env.VUE_APP_SERVER_API + "/getcompanies/renewal";
      this.api.callbackReset = () => {
         this.api.isLoading = true;
         this.api.isError = false;
      };
      this.api.callbackError = (e) => {
         this.api.isLoading = false;
         this.api.isError = true;
         this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
         if (resp.class == "getCompaniesClient" || resp.class == "getCompaniesClientBasedOnPIC") {
            this.data = resp.data.companies;
            this.totalPage = resp.data.totalPage;
            this.totalCompanies = resp.data.totalCompanies;

            this.firstLoading.isLoading = false;
            this.loadingData = false;

         }
         // if(resp.status == "success")
         // {
         //     if(this.api.url== process.env.VUE_APP_SERVER_API+"/companies/getsubscription?status="+this.statusSearch+"&page="+this.nextPage+"&companyName="+this.companyName+"&salespersonName="+this.salespersonName+"&"+this.sort.order+"="+this.sort.column+"&"+this.userRole+"="+this.$store.getters.getEmail){
         //         this.totalPage = resp.total;
         //         this.data = (resp.data)
         //         this.totalCompanies = resp.totalCompanies;
         //     }
         //     if(this.api.url==process.env.VUE_APP_SERVER_API+"/plan/all"){
         //         this.plan_available = JSON.parse(JSON.stringify(resp.data));
         //     }
         //     if(this.api.url==process.env.VUE_APP_SERVER_API+"/user/role/salesperson")
         //     {
         //         this.salesPerson = JSON.parse(JSON.stringify(resp.data));
         //     }
         //     this.loadingData = false;
         // }
      }
   },
   methods:
   {
      clearSearch() {
         this.companyName = null;
         this.salespersonName = null;

         this.fetchCompanies(1);
      },
      sortFetchCompanies(order, column) {
         this.sort.order = order;
         this.sort.column = column;
         this.fetchCompanies(1);
      },
      fetchCompanies(page) {

         let formData = new FormData();
         let order = this.sort.order;
         let column = this.sort.column;
         this.loadingData = true;
         this.api.method = "POST";
         this.nextPage = this.pageNumber = page;
         this.api.url = process.env.VUE_APP_SERVER_API + "/companies/client";
         let seeAllRoles = ['operation', 'vision_admin', 'customer_service', 'developer', 'sales_manager']
         if (!seeAllRoles.some(role => this.$store.getters.getRole.includes(role))) {
            this.api.url = process.env.VUE_APP_SERVER_API + "/companies/client/pic/email/" + this.$store.getters.getEmail;

         }
         // for operation, will get all the list of the companies, but for other roles, will only get the companies being assigned to them.
         // if(this.$store.getters.getRole!='operation' && this.$store.getters.getRole!='vision_admin'&&this.$store.getters.getRole!='developer'){
         // }
         if (this.companyName != null) {
            formData.append('companyName', this.companyName);
         }
         if (this.statusSearch != null) {
            formData.append('status', this.statusSearch)
         }
         formData.append(order, column);
         formData.append("page", this.nextPage);
         this.api.params = formData;
         return this.$api.fetch(this.api);
      },
      fetchPlanAvailable() {
         this.api.url = process.env.VUE_APP_SERVER_API + "/plan/all";
         return this.$api.fetch(this.api);
      },
      fetchSalesPerson() {
         this.api.url = process.env.VUE_APP_SERVER_API + "/user/role/salesperson";
         return this.$api.fetch(this.api);

      },
      editCompanies(item) {
         this.dialog = true;
         this.fetchIndividualCompany(item);
      },
      openNotes(item) {
         this.fetchIndividualCompany(item);
         this.dialogNote = true;
      },
      fetchIndividualCompany(item) {
         // BOC : [ construct edited companies object]
         this.editedCompanies.id = item.company_id;
         this.editedCompanies.created_at = item.created_at;
         this.editedCompanies.name = item.name;
         this.editedCompanies.plan.plan_name = item.plan_name;
         this.editedCompanies.plan.id = item.plan_id;
         this.editedCompanies.renew_at = item.renew_at;
         this.editedCompanies.status = item.status;
         this.editedCompanies.pic.role_id = item.salesperson_id;
         this.editedCompanies.pic.names = item.salesperson_name;
         this.editedCompanies.pic.mobile = item.mobile;
         this.editedCompanies.pic.user_email = item.email;
         this.editedCompanies.address = item.address;
         this.editedCompanies.companySize = item.company_size;
         this.editedCompanies.industry = item.industry;

         // EOC
      },
      readCompany(item) {
         this.$store.commit("updateCompany", item.company_id);
         this.$router.push({ name: 'PageCompaniesDetail', params: { 'id': item.company_id } });
         // let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':item.company_id}});
         // window.open(route.href,'_blank')
      },
      closeDialog() {
         this.dialog = false;
         this.dialogNote = false;
      },
      convertTimeZone(time) {
         return this.$moment(time).format('LLL')
         // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
      },

   }
}
</script>